import React from "react"
import { motion } from "framer-motion"
import { images } from "../../constants"
import "./About.scss"

import { AppWrap, MotionWrap } from "../../wrapper"

const About = () => {
  const abouts = [
    { title: "Frontend", description: "Responsible for developing new user-facing features, determining the structure and design of web pages.", imgUrl: images.fe },
    { title: "Backend", description: "Collaborate with Front-end developers to integrate user-facing elements with server side logic.", imgUrl: images.backend },
    { title: "Database", description: "Hands-on experience with database standards and end user applications using MongoDB.", imgUrl: images.api },
  ]
  return (
    <>
      <h2 className="head-text">
        I Know that <span>Good Development</span> <br />
        means <span>Good Business</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div whileInView={{ opacity: 1 }} whileHover={{ scale: 1.1 }} transition={{ duration: 0.5, type: "tween" }} className="app__profile-item" key={about.title + index}>
            <img src={about.imgUrl} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  )
}

export default AppWrap(MotionWrap(About, "app__about"), "about", "app__whitebg")
